import * as types from './actionTypes';
import { history } from '~store/history';

let currentAuth = {};

function select(state) {
  return state.auth;
}
export const onLoginLogout = state => {
  let previousValue = currentAuth;
  currentAuth = select(state);
  if (currentAuth?.isLogin === previousValue?.isLogin) {
    return;
  }
  if (previousValue?.isLogin === true && currentAuth?.isLogin === false) {
    return history.push('/');
  }
};

const initialState = {
  isLogin: false,
  account: null,
  resetpass: null,
  methodLogin: null,
  locale: null,
  isShowLogin: false,
  paidContents: [],
};

export function auth(state = initialState, action) {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        account: typeof action.account === 'object' ? action.account : null,
        methodLogin: action.methodLogin || null,
        isLogin: true,
        isShowLogin: false,
      };
    case types.SIGNOUT_SUCCESS:
      return {
        ...state,
        account: null,
        isLogin: false,
        paidContents: [],
      };

    case types.RESETPASS_SUCCESS:
      return {
        ...state,
        resetpass: {
          otp: action.otp,
          reset: action.reset,
        },
      };
    case types.RESETPASS_FAILURE:
      return {
        ...state,
        resetpass: {
          otp: action.otp,
          reset: false,
        },
      };

    case types.REQUESTCODE_SUCCESS:
      return {
        ...state,
        resetpass: {
          otp: action.otp,
        },
      };
    case types.REQUESTCODE_FAILURE:
      return {
        ...state,
        resetpass: {
          otp: action.error,
        },
      };
    case types.REGISTER_LOGIN_SUCCESS:
      return {
        ...state,
        account: action.account,
        isLogin: true,
        methodLogin: action.methodLogin || null,
      };
    case types.UPDATE_EMAIL:
      return {
        ...state,
        account: {
          ...state.account,
          profile: {
            ...state.account.profile,
            email: action.email,
            email_confirmed: false,
          },
        },
      };
    case types.UPDATE_FIRSTNAME:
      return {
        ...state,
        account: {
          ...state.account,
          profile: {
            ...state.account.profile,
            first_name: action.first_name,
          },
        },
      };
    case types.GET_PROFILE:
    case types.UPDATE_PROFILE_SUCCESS:
      if (!state.account) {
        return state;
      }
      return {
        ...state,
        account: {
          ...state.account,
          profile: action.account,
        },
      };

    case types.UPDATE_ACCOUNT_SUPSCRIPTION:
      return {
        ...state,
        account: {
          ...state.account,
          profile: {
            ...state.account.profile,
            has_subscription: action.account.has_subscription,
          },
        },
      };
    case types.UPDATE_ACCOUNT_PAYMENT_METHOD:
      return {
        ...state,
        account: {
          ...state.account,
          paypalMethod: [],
          paymentMethod: action.payload,
        },
      };
    case types.UPDATE_ACCOUNT_PAYMENT_METHOD_PAYPAL:
      return {
        ...state,
        account: {
          ...state.account,
          paymentMethod: [],
          paypalMethod: action.payload,
        },
      };
    case types.UPDATE_IP:
      return {
        ...state,
        locale: {
          ip: action.payload,
        },
      };
    case types.SET_IS_SHOW_LOGIN:
      return {
        ...state,
        isShowLogin: action.payload,
      };
    case types.SET_PAID_CONTENT:
      return {
        ...state,
        paidContents: action.payload,
      };
    case types.RESET_PAYMENT_METHOD:
      return {
        ...state,
        account: null,
      };
    default:
      return state;
  }
}

export const accountSelector = state => state?.auth?.account ?? null;
